<template>
  <div class="space-y-4">
    <el-collapse accordion v-model="active">
      <device-block
        v-for="(orderDevice, index) in $state.order.orderDevices"
        :key="`orderDevice-${index}`"
        :orderDeviceIndex="index"
        @removeDevice="removeDevice"
        :active="active" />
    </el-collapse>
    <div
      class="px-4 py-6 text-gray-400 transition-colors border-2 border-gray-300 border-dashed rounded cursor-pointer hover:text-purple-500 hover:border-purple-400"
      @click="addDevice">
      <span class="flex flex-row items-center">
        <i class='bx bx-plus' />
        <span class="ml-2">Agregar dispositivo</span>
      </span>
    </div>
  </div>
</template>
<script>
import { state, mutations } from '../store';

export default {
  name: 'DevicesList',
  components: {
    DeviceBlock: () => import('./DeviceBlock.vue')
  },
  props: {
    activeDevice: {
      type: Number || null,
      default: () => null,
    }
  },
  data() {
    return {
      active: this.activeDevice,
    }
  },
  created() {
    this.$state = state;
  },
  watch: {
    activeDevice(newActiveDevice) {
      this.active = newActiveDevice;
    },
  },
  methods: {
    addDevice() {
      mutations.addOrderDevice();
      this.active = this.$state.order.orderDevices.length - 1;
    },
    removeDevice(orderDeviceIndex) {
      mutations.removeOrderDevice(orderDeviceIndex);
    },
  },
}
</script>
